import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { useDocumentSnippet } from '../../hooks/useDocument';

import DocumentHorizontalCard from '../../components/cards/DocumentHorizontalCard';

import styles from '../../styles/animations/bnc-fraude/document-embed.module.css';

const propTypes = {
    uri: PropTypes.string,
    theme: PropTypes.string,
    authorOverride: PropTypes.string,
    withoutDate: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    uri: null,
    theme: null,
    authorOverride: null,
    withoutDate: null,
    className: null,
};

function DocumentEmbed({ uri, theme, authorOverride, withoutDate, className }) {
    const { document: snippet = null } = useDocumentSnippet(uri);
    const {
        type = null,
        slug = null,
        date = null,
        title = null,
        image = null,
        season = null,
        episode = null,
        collection = null,
        duration = null,
        sponsors = null,
        credits = null,
        categories = null,
        brands = null,
        locations = null,
    } = snippet || {};

    // console.log('snippet', snippet);

    return (
        <div className={classNames(styles.container, className)}>
            {snippet !== null ? (
                <DocumentHorizontalCard
                    theme={theme}
                    type={type}
                    slug={slug}
                    title={title}
                    image={image}
                    date={date}
                    withoutDate={withoutDate === 'true'}
                    withoutTime={withoutDate === 'true'}
                    credits={
                        authorOverride !== null ? [{ author: { name: authorOverride } }] : credits
                    }
                    categories={categories}
                    brands={brands}
                    locations={locations}
                    sponsors={sponsors}
                    duration={duration}
                    season={season}
                    episode={episode}
                    collection={collection}
                    className={styles.card}
                />
            ) : null}
        </div>
    );
}

DocumentEmbed.propTypes = propTypes;
DocumentEmbed.defaultProps = defaultProps;

export default DocumentEmbed;
